<script>
import { mapState, mapActions } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import appConfig from '../../../src/app.config';

export default {
  locales: {
    pt: {
      'Edit': 'Editar'
    },
    es: {
      'Edit': 'Editar',
    }
  },
  components: { AvatarCropper },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: false,
      message: '',
      url: appConfig.apiUrl+'user/avatar',
      name: 'avatar',
      headers: {
        'x-auth-token': window.localStorage.token
      },
      options: {
        width: 500,
        height: 500
      },
      quality: 0.9,
      labels: {
        submit: 'Salvar',
        cancel: 'Cancelar',
      }
    }
  },
  methods: {
    ...mapActions("account", ["avatar"]),
    uploadHandler() {
    },
    handleUploading() {
      this.loading = true
      this.message = ""
    },
    handleUploaded() {
    },
    handleCompleted(response) {
      if (response.status == "success") {
        this.$store.state.account.user.avatar = response.avatar
        this.message = ''
        this.loading = false
        this.avatar()
      }
    },
    handlerError() {
      this.message = "Oops! Something went wrong..."
    }
  }
};
</script>

<template>
  <div class="text-center">
    <div class="mx-auto my-3 box-avatar">
      <button id="pick-avatar" class="btn btn-danger"><span class="text-white"><i class="fas fa-pencil-alt align-middle"></i> {{ t('Edit') }}</span></button>
      <div v-if="loading" class="loading bg-soft-danger justify-content-center">
        <b-spinner v-if="loading" class="m-5 p-5 align-middle" variant="secondary" role="status"></b-spinner>
      </div>
      <img v-else-if="account.user.avatar" :src="account.user.avatar" class="card-img avatar rounded-circle" />
      <img v-else src="@/assets/images/logo-artesanato-icon.png" class="card-img avatar rounded-circle" />
    </div>
    <div v-if="message" class="card-footer text-muted" v-html="message"></div>
    <avatar-cropper
      @uploading="handleUploading"
      @uploaded="handleUploaded"
      @completed="handleCompleted"
      @error="handlerError"

      :upload-url="url"
      :upload-headers="headers"
      :upload-form-name="name"
      :output-options="options"
      :output-quality="quality"
      :labels="labels"
      trigger="#pick-avatar" />
  </div>
</template>

<style>
.loading {
  width: 200px;
  height: 200px;
}
.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
  padding: 10px;
}
.avatar {
  width: 200px;
  display: block;
}
#pick-avatar {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.box-avatar {
  max-width: 200px;
  height: 200px;
  position: relative;
}
.avatar-cropper-container {
  padding: 15px;
}

.avatar-cropper .avatar-cropper-mark {
  background: rgba(0, 0, 0, 0.5);
}
.avatar-cropper-btn:hover {
  background-color: #f14646 !important;
}
.avatar-cropper-btn + .avatar-cropper-btn {
  background-color: #92d050 !important;
  color: #fff;
}
.avatar-cropper-btn + .avatar-cropper-btn:hover {
  background-color: #69b814 !important;
}
.avatar-cropper-footer {
  margin-top: 15px;
}
.avatar-cropper-footer button:first-child {
  display:none;
}
.avatar-cropper-footer .avatar-cropper-btn {
  width: 100% !important;
}
</style>